(function ($) {
  function setCookie(key, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + ';path=/';
  }
  function getCookie(key) {
    if (document.cookie.includes(key + "=")) {
      return document.cookie.split('; ').find(function (row) {
        return row.startsWith(key);
      }).split(';')[0].split('=')[1];
    } else {
      return '';
    }
  }
  function removeCookie(key) {
    document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/';
  }
  $(document).ready(function () {
    $(document.body).on('click', '.btn-wishlist', function () {
      var cookie_id = $(this).data('posttype') + '_in_wishlist';
      var cookie_value = getCookie(cookie_id);
      var wishlist_indicator = document.getElementById('wishlist-indicator');
      if (!$(this).hasClass('favorited') && '' === cookie_value) {
        //create the cookie
        setCookie(cookie_id, $(this).data('postid').toString());
        wishlist_indicator.classList.remove('empty');
        wishlist_indicator.classList.add('has-items');
      } else {
        var cookie_values = cookie_value.split(',');
        if ($(this).hasClass('favorited') && cookie_values.includes($(this).data('postid').toString())) {
          //we remove it from the cookies
          cookie_values.splice(cookie_values.indexOf($(this).data('postid').toString()), 1);
          if (cookie_values.length === 0) {
            removeCookie(cookie_id);
            wishlist_indicator.classList.remove('has-items');
            wishlist_indicator.classList.add('empty');
          } else {
            setCookie(cookie_id, cookie_values.toString());
          }
        } else if (!$(this).hasClass('favorited') && !cookie_values.includes($(this).data('postid').toString())) {
          //we add it to the cookies
          cookie_values.push($(this).data('postid').toString());
          setCookie(cookie_id, cookie_values.toString());
        }
      }
      //we tell the front, we've done the work
      $(this).toggleClass('favorited');
    });
  });
})(jQuery); // Fully reference jQuery after this point.