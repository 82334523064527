// twentytwenty theme video resize fonction

var vtx = vtx || {};
vtx.intrinsicRatioVideos = {
  init: function init() {
    this.makeFit();
    window.addEventListener('resize', function () {
      this.makeFit();
    }.bind(this));
  },
  makeFit: function makeFit() {
    document.querySelectorAll('iframe, object, video').forEach(function (video) {
      var ratio,
        iTargetWidth,
        container = video.parentNode;

      // Skip videos we want to ignore.
      if (video.classList.contains('intrinsic-ignore') || video.parentNode.classList.contains('intrinsic-ignore')) {
        return true;
      }
      if (!video.dataset.origwidth) {
        // Get the video element proportions.
        video.setAttribute('data-origwidth', video.width);
        video.setAttribute('data-origheight', video.height);
      }
      iTargetWidth = container.offsetWidth;

      // Get ratio from proportions.
      ratio = iTargetWidth / video.dataset.origwidth;

      // Scale based on ratio, thus retaining proportions.
      video.style.width = iTargetWidth + 'px';
      video.style.height = video.dataset.origheight * ratio + 'px';
    });
  }
}; // vtx.instrinsicRatioVideos

function vtxDomReady(fn) {
  if (typeof fn !== 'function') {
    return;
  }
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    return fn();
  }
  document.addEventListener('DOMContentLoaded', fn, false);
}
vtxDomReady(function () {
  vtx.intrinsicRatioVideos.init(); // Retain aspect ratio of videos on window resize.
});