/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var __ = wp.i18n.__;
var _x = wp.i18n._x;
var _n = wp.i18n._n;
var _nx = wp.i18n._nx;
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function init() {
        // detects if user is using the keyboard to navigate and if so, adds a class to body
        function handleFirstTab(e) {
          if (e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 13 || e.keyCode === 37 || e.keyCode === 39) {
            // the "I am a keyboard user" key
            document.body.classList.add('user-is-tabbing');
            window.removeEventListener('keydown', handleFirstTab);
          }
        }
        window.addEventListener('keydown', handleFirstTab);

        //credits..
        // console.log(
        //   '%c  \n' +
        //   '%cVortex Solution \n' +
        //   'https://www.vortexsolution.com',
        //   'font-size:30px; background: #fff url(https://www.vortexsolution.com/wp-content/themes/vortex-solution/assets/favicon/favicon-32x32.png) no-repeat; color:#fff;',
        //   'background: #fff; color:#00a6e9;font-weight:bold;'
        // );
      },
      finalize: function finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function init() {
        // JavaScript to be fired on the home page
      },
      finalize: function finalize() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function init() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

var elements_with_trapped_focus = [];
var trapped_elements_id = 0;
function get_focusable_children_of($el) {
  var $focusables = $el.find('[tabindex="0"], a, input, textarea, button').not('[tabindex="-1"]').filter(':visible');
  var $first = $focusables.first();
  if ($first.is('[type="radio"]')) {
    $el.find('[name="' + $first.attr('name') + '"]').addClass('first-focusable').data('trapped-in', trapped_elements_id);
  } else {
    $first.addClass('first-focusable').data('trapped-in', trapped_elements_id);
  }
  var $last = $focusables.last();
  if ($last.is('[type="radio"]')) {
    $el.find('[name="' + $last.attr('name') + '"]').addClass('last-focusable').data('trapped-in', trapped_elements_id);
  } else {
    $last.addClass('last-focusable').data('trapped-in', trapped_elements_id);
  }
  return $focusables;
}
function get_trap_by_id(trap_id) {
  for (var i = elements_with_trapped_focus.length - 1; i >= 0; i--) {
    if (trap_id === elements_with_trapped_focus[i].data('trap-id')) {
      return elements_with_trapped_focus[i];
    }
  }
  return false;
}
function check_focus_position(e) {
  var $focused = jQuery(e.target);
  var $trap;

  // Is going reverse
  if (e.shiftKey) {
    if ($focused.hasClass('first-focusable')) {
      $trap = get_trap_by_id($focused.data('trapped-in'));
      if ($trap) {
        e.preventDefault();
        var $last = $trap.find('.last-focusable');
        if ($last.is('[type="radio"]')) {
          $last.filter(':checked').focus();
        } else {
          $last.focus();
        }
      }
    }

    // Focus is going forward
  } else {
    if ($focused.hasClass('last-focusable')) {
      $trap = get_trap_by_id($focused.data('trapped-in'));
      if ($trap) {
        e.preventDefault();
        var $first = $trap.find('.first-focusable');
        if ($first.is('[type="radio"]')) {
          $first.filter(':checked').focus();
        } else {
          $first.focus();
        }
      }
    }
  }
}
function untrap_focus_inside($el) {
  var trap_id = $el.data('trap-id');
  for (var i = elements_with_trapped_focus.length - 1; i >= 0; i--) {
    if (trap_id === elements_with_trapped_focus[i].data('trap-id')) {
      // Completely remove focus information from object
      elements_with_trapped_focus[i].removeData('trap-id').removeClass('focus-trap').off('keydown.trap');

      // Remove element from array
      elements_with_trapped_focus.splice(i, 1);

      // Job's done.
      break;
    }
  }
}
function trap_focus_inside($el) {
  if ($el.hasClass('focus-trap')) {
    return;
  }
  trapped_elements_id++;
  $el.addClass('focus-trap');
  $el.data('trap-id', trapped_elements_id);
  $el.allFocusableChildren = get_focusable_children_of($el);
  elements_with_trapped_focus.push($el);
  $el.on('keydown.trap', function (e) {
    // SHIFT
    if (9 === e.keyCode) {
      check_focus_position(e);
    }
  });
}