(function ($) {
  var timer;
  var lastScrollTop = 200;
  function verifyStickyMenu() {
    var st = $(window).scrollTop();
    if (st > lastScrollTop) {
      $('body').addClass('sticky-menu');
    } else {
      $('body').removeClass('sticky-menu');
    }
    lastScrollTop = st + 40;
  }
  $(window).scroll(function (e) {
    if (timer) {
      window.clearTimeout(timer);
    }
    timer = window.setTimeout(function () {
      // actual callback
      verifyStickyMenu();
    }, 100);
  });
})(jQuery);