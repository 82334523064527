(function ($) {
  var main_site_toggle = $('.main-site-dropdown__toggle');
  var main_site_dropdown = $('.main-site-dropdown');
  var student_toggle = $('.student-dropdown__toggle');
  var student_dropdown = $('.student-dropdown');
  var search_popup = $('#search-popup');
  var global_overlay = $('#site-menu-overlay');
  var mobile_overlay = $('#site-menu-overlay-mobile');
  function open_popup(button, popup, overlay, el_to_focus) {
    button.attr('aria-expanded', 'true');
    popup.addClass('opened');
    overlay.addClass('active');
    trap_focus_inside(popup);
    if (el_to_focus) {
      el_to_focus.focus();
    }
    $('body').css({
      'height': '100%',
      'overflow': 'hidden'
    });
  }
  function close_popup(button, popup, overlay) {
    button.attr('aria-expanded', 'false');
    popup.removeClass('opened');
    overlay.removeClass('active');
    untrap_focus_inside(popup);
    $('body').css({
      'height': 'auto',
      'overflow': 'visible'
    });
  }

  // ON READY
  $(function () {
    // college maisonneuve dropdown
    if (!$('html').hasClass('touchevents')) {
      main_site_dropdown.on('mouseenter', function () {
        main_site_dropdown.addClass('opened');
        main_site_toggle.attr('aria-expanded', 'true');
        mobile_overlay.addClass('active');
      });
      main_site_dropdown.on('mouseleave', function () {
        main_site_dropdown.removeClass('opened');
        main_site_toggle.attr('aria-expanded', 'false');
        mobile_overlay.removeClass('active');
      });
    }
    main_site_toggle.on('click', function () {
      if (main_site_dropdown.hasClass('opened')) {
        main_site_dropdown.removeClass('opened');
        main_site_toggle.attr('aria-expanded', 'false');
        mobile_overlay.removeClass('active');
      } else {
        // fermer dropdown etudiant
        if (student_dropdown.hasClass('opened')) {
          student_dropdown.removeClass('opened');
          student_toggle.attr('aria-expanded', 'false');
          mobile_overlay.removeClass('active');
        }
        // ouvrir dropdown college
        main_site_dropdown.addClass('opened');
        main_site_toggle.attr('aria-expanded', 'true');
        mobile_overlay.addClass('active');
      }
    });

    // espace etudiant dropdown
    if (!$('html').hasClass('touchevents')) {
      student_dropdown.on('mouseenter', function () {
        student_dropdown.addClass('opened');
        student_toggle.attr('aria-expanded', 'true');
        mobile_overlay.addClass('active');
      });
      student_dropdown.on('mouseleave', function () {
        student_dropdown.removeClass('opened');
        student_toggle.attr('aria-expanded', 'false');
        mobile_overlay.removeClass('active');
      });
    }
    student_toggle.on('click', function () {
      if (student_dropdown.hasClass('opened')) {
        student_dropdown.removeClass('opened');
        student_toggle.attr('aria-expanded', 'false');
        mobile_overlay.removeClass('active');
      } else {
        // fermer dropdown college
        if (main_site_dropdown.hasClass('opened')) {
          main_site_dropdown.removeClass('opened');
          main_site_toggle.attr('aria-expanded', 'false');
          mobile_overlay.removeClass('active');
        }
        // ouvrir dropdown student
        student_dropdown.addClass('opened');
        student_toggle.attr('aria-expanded', 'true');
        mobile_overlay.addClass('active');
      }
    });

    // search popup
    $('#search-toggle').on('click', function (e) {
      open_popup($(this), search_popup, global_overlay, $('#search'));
    });
    $('#search-close').on('click', function (e) {
      close_popup($('#search-toggle'), search_popup, global_overlay);
    });
  });
})(jQuery);