(function ($) {
  if ($(".accordion").length > 0) {
    $(".accordion__accordion.open > .accordion__sub-rows").slideDown();
    $(".accordion__header-row").click(function () {
      $(this).parent(".accordion__accordion").toggleClass("open");
      $(this).attr("aria-expanded", true);
      if ($(this).parent(".accordion__accordion").hasClass("open")) {
        $(this).next(".accordion__sub-rows").slideDown();
      } else {
        $(this).next(".accordion__sub-rows").slideUp();
        $(this).attr("aria-expanded", false);
      }
      vtx.intrinsicRatioVideos.makeFit();
    });
  }
})(jQuery); // Fully reference jQuery after this point.